.orderMain {
    background: #2B2C40;
    padding: 10px;
    margin-top: 14px;
    border-radius: 10px 10px 0 0;
}

.orderDetailsWrapper {
    background: #232333;
    padding: 15px;
    border-radius: 8px;
}

.orderDetailsWrapper .orderDetailsName,
.orderDetailsWrapper .orderDetailsId {
    display: flex;
    align-items: baseline;
    gap: 10px;
}

.orderDetailsWrapper .orderDetailsTitle {
    min-width: 120px;
    color: #fff;
}

.orderDetailsWrapper .orderDetailsdisc {
    color: #fff;
}

/* 12 - 03 - 2024 */
.header {
    height: 60px;
    width: 100%;
    max-width: 400px;
    background: #2a2c3f;
    z-index: 9999;
    position: fixed;
    /* border-radius: 0 0 10px 10px; */
}

.delivery_title p {
    font-size: 16px;
    color: #fff;
    font-weight: 400;
    margin-bottom: 10px;
}

.driver_name_section {
    padding: 15px;
    background-color: #232333;
    border-radius: 10px;
    margin-top: 10px;
}

.driver_name_section_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.driver_user_box {
    display: flex;
    align-items: center;
    gap: 10px;
}

.driver_user_image {
    min-width: 50px;
    height: 50px;
}

.driver_user_image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.call_btn a {
    background-color: #696CFF;
    border-radius: 8px;
    border: 0;
    font-size: 16px;
    color: #fff;
    font-weight: 400;
    text-transform: capitalize;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 6px;
}

.call_btn a:hover {
    background-color: #494ef5;
}

.driver_title p {
    font-size: 16px;
    color: #fff;
    font-weight: 400;
}

.delivery_status_container {
    background-color: #232333;
    padding: 15px;
    border-radius: 12px;
    width: 100%;
    color: white;
    margin: 12px auto;
}

.delivery_status_main {
    margin: 12px auto 0;
}

.delivery_status_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    border-bottom: 1px solid #2B2C40;
    gap: 5px,

}

.delivery_status_header h2 {
    font-size: 16px;
    font-weight: 400;
    width: 179px;
}

.delivery_status_header .refresh-btn {
    background-color: #696CFF;
    border: none;
    border-radius: 6px;
    color: white;
    cursor: pointer;
    display: flex;
    font-weight: 400;
    align-items: center;
    gap: 4px;
    font-size: 16px;
    text-transform: capitalize;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
}

.delivery_status_header .refresh-btn:hover {
    background-color: #494ef5;
}

.delivery_status_header .refresh-btn svg {
    width: 20px;
    height: 20px;
}


.timeline {
    position: relative;
    margin-top: 15px;
}

.timeline-item::before {
    content: '';
    position: absolute;
    left: 15px;
    width: 2px;
    height: 100%;
    background-color: #404040;
}


.timeline-item:last-child:before {
    display: none;
}

.timeline-item {
    display: flex;
    padding-bottom: 20px;
    position: relative;
}

.timeline-dot {
    width: 30px;
    height: 30px;
    background-color: #232333;
    border: 1px solid #404040;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
    z-index: 1;
}

.timeline-dot.success {
    background-color: #4caf50;
}

.timeline-content {
    flex: 1;
}

.timeline_eta {
    font-size: 12px;
}

.warning_color {
    background-color: #0288d1;
}

.active_status {
    border: 2px solid #fff;
    background-color: #696CFF;
}

.status {
    margin-bottom: 4px;
    font-size: 16px;
    font-weight: 400;
    max-width: 200px;
}

.time {
    color: #808080;
    font-size: 10px;
    position: absolute;
    left: -40px;
    top: 8px;
}

.estimate-card {
    background-color: #2a2a3e;
    padding: 8px;
    border-radius: 6px;
    margin: 8px 0;
    font-size: 12px;
}

.now-label {
    color: #7c7cf5;
    font-size: 12px;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 30px
}

.main_logo {
    width: 45px;
    height: 34px;
}

.main_logo img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.header .left_arrow button {
    width: 45px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #696CFF;
    border-radius: 5px;
    font-size: 16px;
    position: relative;
    min-width: 0;
    padding: 0;
}

.header .left_arrow button svg {
    fill: #fff;
    width: 20px;
    height: 20px;
}

.header .left_arrow button .top_arrow {
    width: 28px;
    height: 28px;
}

.header .left_arrow button .left_toggle_arrow {
    position: absolute;
    right: 10px;
}

.order_delivered_box {
    height: 100vh;

    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}

.order_delivered_text {
    max-width: 500px;
    background-color: #696CFF;
    display: flex;
    align-items: center;
    max-width: 500px;
    padding: 20px;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    margin: 0 auto;
    gap: 30px;
    border-radius: 20px;
}


.order_delivered_text svg {
    width: 150px;
    height: 150px;
    fill: #fff;
}

.order_delivered_text p {
    font-size: 24px;
    font-weight: 400;
    color: #fff;
}

@media only screen and (min-width: 1650px) {
    .orderMain {
        height: 100vh;
    }
}

@media only screen and (max-width: 599px) {
    .header {
        position: fixed;
        bottom: 0;
        z-index: 99;
        max-width: 100%;
    }

    .orderMain {
        max-height: calc(100% - 50px);
        overflow-y: auto;
    }

    .swipeable_drawer .MuiDrawer-paper {
        height: auto !important;
        max-height: calc(100% - 50px);
        background-color: #2a2c3f !important;
        border-radius: 20px 20px 0 0
    }

    .swipeable_drawer .MuiDrawer-paper::before {
        position: absolute;
        content: '';
        width: 50px;
        height: 6px;
        border-radius: 50px;
        background-color: #57586D;
        top: 10px;
        left: 0;
        right: 0;
        margin: 0 auto;
    }

    .header .left_arrow button {
        width: 45px;
        height: 34px;
    }

    .orderDetailsWrapper .orderDetailsTitle,
    .orderDetailsWrapper .orderDetailsdisc,
    .driver_title p,
    .status {
        font-size: 14px;
    }

    .call_btn a,
    .delivery_status_header .refresh-btn {
        font-size: 14px;
    }

    .order_delivered_text svg {
        width: 100px;
        height: 100px;
    }

    .order_delivered_text {
        margin: 10px;
    }

    .order_delivered_text p {
        font-size: 20px;
    }
}

/* End 12 - 03 - 2024 */